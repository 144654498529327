import React, { useState } from 'react'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import ReactMarkdown from 'react-markdown'
import './index.css'

export default function AppExplanations() {
  const [expandedItems, setexpandedItems] = useState([])

  // In case the user expands a node that is barely visible, we scroll the page to display it fully
  function handleExpand(update) {
    if (update.length > expandedItems.length) {
      const newExpandedItemUUID = update[update.length - 1]
      const itemButtonBottom = document
        .getElementById(`accordion__panel-${newExpandedItemUUID}`)
        .getBoundingClientRect().bottom
      if (itemButtonBottom > window.innerHeight) {
        window.scrollBy(0, itemButtonBottom - window.innerHeight)
      }
    }
    setexpandedItems(update)
  }

  const whatIsAFaucet_help =
    'A `Faucet` is a tool that provides a small amount of funds to start using a cryptocurrency without having to buy some. This service is built by the [MMF](https://polymm.finance) team and sponsored by `Polygon` team!'

  const howMuchCanIGet_help =
    'This faucet will only send you 0.02 MATIC - which is enough to cross chain funds over from other chains to polygon via [multichain](https://app.multichain.org/#/router) and doing swaps on [MMF Dex](https://polymm.finance/).'

  return (
    <Accordion allowZeroExpanded allowMultipleExpanded onChange={handleExpand}>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>What is a Faucet ?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ReactMarkdown
            className="Explanations"
            children={whatIsAFaucet_help}
          ></ReactMarkdown>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>How much can I get ?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ReactMarkdown
            className="Explanations"
            children={howMuchCanIGet_help}
          ></ReactMarkdown>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}
